@import "src/app/core/scss/variables";
@import "src/app/core/scss/functions";

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWebBold';
  font-display: block;
  src: url('/assets/fonts/Lato-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: 'LatoWebMedium';
  font-display: block;
  src: url('/assets/fonts/Lato-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'LatoWeb';
  font-display: block;
  src: url('/assets/fonts/Lato-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoWebLight';
  font-display: block;
  src: url('/assets/fonts/Lato-Light.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
